$primary: #627261 !default;
$danger: #c2765a !default;
$info: #b2cacc !default;
$warning: #c7ac9c !default;
$success: #627261 !default;
$white: #f4ede1 !default;
//test input
body {
  overflow: hidden;
  overflow-y: auto !important;
  -ms-overflow-y: auto !important;
  -webkit-overflow-scrolling: auto !important;
  font-size: 18px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent !important;
}

.nav.nav-tabs {
  float: left;
  display: block;
  margin-right: 20px;
  border-bottom: 0;
  border-right: 1px solid transparent !important;
  padding-right: 15px;
}

.nav.nav-tabs .nav-item {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  min-height: 100px;
}

.nav-tabs .nav-link {
  border: none !important;
  border-radius: 0.24rem 0 0 0.24rem;
  background: #627261 !important;
  min-height: 80px;
  width: 101% !important;
  display: flex;
  align-items: stretch;
  justify-content: center;
  text-align: right;
  flex-direction: column;
}

.nav-tabs .nav-link.active {
  color: #627261;
  background-color: $white !important;
  border-color: transparent !important;
  border-radius: 0.24rem 0 0 0.24rem;

  box-shadow: rgba(50, 50, 93, 25%) 0 6px 12px -2px, rgba(0, 0, 0, 30%) 0px 3px 7px -3px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  -webkit-box-shadow: rgba(50, 50, 93, 25%) 0 6px 12px -2px, rgba(0, 0, 0, 30%) 0px 3px 7px -3px;
  -o-box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}

.tab-content {
  transition: ease all 800ms;
  width: 100%;
  position: relative;
  border-top:1px solid $warning;
  border-left:1px solid $warning;
}

.nav-tabs .nav-link {
  border: none !important;
}

.tab-content > .active {
  display: block;
  background: transparent !important;
  min-height: 500px;
}

.nav.nav-tabs {

  border-bottom: 0;
  //border-right: 1px solid $warning!important;
  margin-right: 2rem;
  padding-right: 0 !important;
  background: #627261;
  //min-height: 50vh;
  width: 100%;
}

.ccmo_content_js {
  min-width: 100%;
  margin-bottom: 60px;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}

.sub__title__container {
  padding: 15px;
  border-bottom: 1px solid #c7ac9c;
}

.sub__title__container h2 {
  color: #f4ede1;
  margin: 0.4rem 0;
}

.sub__title__container p {
  color: #c7ac9c;
}

.ccmo_content_js p {
  margin: 0 !important;
}


.fileuploader {
  position: relative;
  max-width: 320px;
}

.fileuploader .universalClickState {
  position: fixed;
  left: -9999vw;
  top: 0;
  display: inline-block;
}

.fileuploader #upload-label {
  cursor: pointer !important;
  border: 1px solid #c7ac9c;
  color: #f4ede1;
  border-radius: 0.24rem;
}

.fileuploader.active {
  background: #c7ac9c;
}

.fileuploader.active #upload-label {
  background: #f4ede1;
  color: #c7ac9c;
}

.fileuploader #upload-label span.title {
  font-size: 1.1em;
  font-weight: bold;
  display: block;
}

.fileuploader #upload-label i {
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  font-size: 60px;
  padding-top: 10px;
}

/** Preview of collections of uploaded documents **/
.preview-container {
  position: fixed;
  right: 10px;
  bottom: 0;
  width: 300px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  visibility: hidden;
}

.preview-container #previews {
  max-height: 400px;
  overflow: auto;
}

.preview-container #previews .zdrop-info {
  width: 88%;
  margin-right: 2%;
}

.preview-container #previews.collection {
  margin: 0;
}

.preview-container #previews.collection .actions a {
  width: 1.5em;
  height: 1.5em;
  line-height: 1;
}

.preview-container #previews.collection .actions a i {
  font-size: 1em;
  line-height: 1.6;
}

.preview-container #previews.collection .dz-error-message {
  font-size: 0.8em;
  margin-top: -12px;
  color: $danger;
}

.preview-container .header {
  background: #c7ac9c;
  color: #f4ede1;
  padding: 8px;
}

.preview-container .header i {
  float: right;
  cursor: pointer;
}

#contact-1, #contact-2, #contact-3 {
  margin: 15px 10px;
}

fieldset {
  position: relative;
  margin-bottom: 30px !important;

  input::placeholder {
	color: $white !important;
  }

  label {
	color: $warning !important;
	align-items: normal !important;
	justify-content: normal !important;
	/* align-items: center; */
	/* justify-content: center; */
	margin-bottom: 15px;
	margin-top: 15px;

	&.btn.btn-outline-warning.active {
	  color: $primary !important;
	}
  }

  .btn-warning, .btn-outline-warning {
	color: #c7ac9c;
	border-color: #c7ac9c;
  }

  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
	color: #212529 !important;
	background-color: #c7ac9c;
	border-color: #c7ac9c;
  }

  .btn-outline-warning:hover {
	color: #212529 !important;
	background-color: #c7ac9c;
	border-color: #c7ac9c;
  }

  input, select, option, textarea {
	border: none !important;
	border-bottom: 1px solid $warning !important;
	outline: none !important;
	color: $white !important;
	box-shadow: none !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-o-box-shadow: none !important;
	margin-top: 15px;
	margin-bottom: 15px;
  }
}

.box {
  position: relative;
  left: 0;
  display: inline-block;
  //min-height: 420px;
  width: 100% !important;
  z-index: 0;

  &.cp1 {
	border: 1px solid transparent;
	background: #627261;
	z-index: 99;
  }

  &.d1 {
	border: 1px solid transparent;
	background: #627261;
	z-index: 99;
  }

  &.cp2 {
	position: absolute;
	border: 1px solid transparent;
	background: #627261;
	left: 200vw;
  }
}

#deceased-tabs {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
	//padding-left: 5px!important;
	//padding-right: 5px!important;
  }
}

h4#cptarget1, h4#cptarget2, #deceased h4, .h4 {
  color: #f4ede1 !important;
  margin-top: 15px;
  font-size: 18px;
  margin-bottom: 0.5rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.block-hidden {
  display: none;
}

.block-shown {
  display: contents;
  margin: 0 -5px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

div.col-sm-12.fp-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pyx-4 {
  padding: 1.9rem !important;
}

#itemRows {
  min-width: 100% !important;
}

.ccmo_content_js {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5 {
	color: #f4ede1 !important;
  }

  .p-30 {
	padding-left: 30px !important;
	padding-right: 30px !important;
  }

  .text-red {
	color: #292323 !important;
	font-weight: 600;
  }

  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
	color: $primary !important;
	background-color: $warning !important;
	border-color: $warning !important;
  }
}


.brh-100 {
  border-left: 1px solid $warning !important;
}


#billing {
  .card {
	* {
	  color: $primary;
	}

	.text-warning, .h2.font-weight-light {
	  color: $warning;
	}

	*.text-white {
	  color: #f4ede1 !important;
	}
  }
}

td > input.form-control-plaintext {
  border: none !important;
  background-color: transparent !important;
}

::-webkit-calendar-picker-indicator {
  background-image: url('../img/cal-hover.svg');
  background-size: 100% !important;

  transition: ease all 300ms;
  -o-transition: ease all 300ms;
  -moz-transition: ease all 300ms;
  -webkit-transition: ease all 300ms;

  &:hover {
	background-image: url('../img/cal-normal.svg');
	background-size: 100% !important;
  }
}

::-webkit-date-and-time-value {
  background-image: url('../img/cal-hover.svg');
  background-size: 100% !important;

  transition: ease all 300ms;
  -o-transition: ease all 300ms;
  -moz-transition: ease all 300ms;
  -webkit-transition: ease all 300ms;

  &:hover {
	background-image: url('../cal-normal.svg');
	background-size: 100% !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.toast-body {
  padding: 0.75rem;
  background-color: #f4ede1;
}

.list-group.bg-primary.border-none {
  background: $primary;
  color: #f4ede1 !important;
  border: none !important;
}


//.btn {
//  //border-radius: 999px
//  border-radius: 0.24rem;
//}
.ccmo_content_js .label.btn.btn-primary {
  border-radius: 0.24rem !important;
}

fieldset > div.btn-group.btn-group-toggle {
  margin-right: 15px;
}

*, body, p, h1, h2, h3, h4, h5, h6, span, li, a form, input {
  font-family: 'Cormorant Garamond', serif;
}

.post-thumbnail, .entry-content .wp-audio-shortcode, .entry-content > *:not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-separator):not(.woocommerce), *[class*=inner-container] > *:not(.entry-content):not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright):not(.wp-block-separator):not(.woocommerce), .default-max-width {
  max-width: var(--responsive--aligndefault-width);
  min-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}

.page-title {
  font-size: 28px;
  color: $white;

  span {
	color: $white;
  }
}

body .nav-link:hover {
  color: $warning !important;
}

.ccmo_content_js .text-red {
  color: #c0c0af !important;
  font-weight: 600;
  font-size: 18px;
}

.btn.btn-outline-light.bally, .btn.btn-outline-light.mt-2 {
  height: 24px;
  width: 24px;
  padding: 0;
}

.btn.btn-outline-light.back {
  margin-left: 30px;
}

.fileuploader {
  margin-bottom: 30px;
}

.product-card {
  background: #E1DED2;
  padding: 50px;
  box-shadow: 0px 0px 4px 0px #414340;
  min-height: 300px;
  border-radius: 0.24rem !important;
}

.ccmo_content_js h1, .ccmo_content_js h2, .ccmo_content_js h3, .ccmo_content_js h4, .ccmo_content_js h5, .ccmo_content_js h6, .ccmo_content_js .h1, .ccmo_content_js .h2, .ccmo_content_js .h3, .ccmo_content_js .h4, .ccmo_content_js .h5 {
  &.text-black {
	color: #101010 !important;
  }

  &.tiny-text {
	font-size: 16px !important;
  }

}

* .tiny-text {
  font-size: 16px !important;
}

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.list-display li {
  margin-bottom: .4rem;
  //font-size:1.1rem;
  color: #627260 !important;
}

.list-checkmarks {
  padding-left: 1.5rem;
}

.list-checkmarks li {
  list-style-type: none;
  //padding-left:1rem;
}

.list-checkmarks li:before {
  font-family: 'FontAwesome';
  content: "\f00c";
  margin: 0 10px 0 -28px;
  color: #627260;
}

.offscreen-radio-check {
  position: fixed;
  left: -900px;
}

.switch {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 50px;
  margin-bottom: 15px !important;
  margin-top: 15px;
  float: left;
}

.switch-helper {
  display: inline-block;
  margin-top: 18px;
  float: left;
  margin-left: 15px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 16px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 16px;
}

input:checked + .slider {
  background-color: #c7ac9c;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

p.disclaimer {
  z-index: 999;
  margin-top: 15px !important;
  font-size: 16px;
  font-weight: normal;

  a {
	font-weight: 600;
  }
}

#rescroller {
  height: 15px;
  width: 100%;
  display: block;
  flex-direction: row;
  float: left;
}