$primary: #627261!default;
$danger: #c2765a!default;
$info: #b2cacc!default;
$warning: #c7ac9c!default;
$success: #627261!default;
$white: #f4ede1!default;


body {
  font-style: normal;
  font-size: 16px;
  color: $white !important;
  background-color: #627261 !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
}

body * {
  border-radius: 0;
  margin: 0;
  padding: 0;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus, select, select:focus, input[type=checkbox]:focus, input[type=radio]:focus {
  border-color: transparent;
  box-shadow:none;
  outline: 0 none;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body .tab-content * p {
  font-weight: 400;
}

/* custom inclusion of right, left and below tabs */

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

.table {
  display: block;
}

.nav-tabs .nav-link.active {
  color: $primary !important;
  background-color: #f4ede1 !important;
  border-color: transparent !important;
  border-radius: 0.24rem 0 0 0.24rem;
}


.fxl {
  font-size: 2.4em;
  line-height: 71px;
}

.sxl {
  font-size: 1.6em;
  color: #c7ac9c;
}

.text-black.fxl {
  font-family: 'Forum', cursive !important;
  font-size: 2.4em !important;
  font-weight: 600 !important;
  line-height: 71px;
  margin-bottom: 4px;
}

.h4.tiny-text.sxl {
  font-family: 'Forum', cursive !important;
  font-size: 1.6em !important;
  color: #c7ac9c !important;
  margin-bottom: 15px;
}

.btn.btn-outline-light.text-left.active, .btn.btn-outline-light.active, .product-card.highlighted {
  border: $primary solid 1px;
  color: $primary !important;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.product-card {
  border: 2px solid transparent;
}

.product-card.highlighted {
  color: $primary !important;
  border: 2px solid $warning;
  background-color: #a39991 !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;

  .h4.tiny-text.sxl {
	color: #f4ede1 !important;
  }
}

.screen-out-input {
  position: fixed;
  left: -999px;
}

.list-display {
  padding: 15px 0 20px;
}

.tab-content {
  label {
	margin-top:15px;
	color: #f4ede1 !important;
  }

  ::placeholder {
	color: transparent !important;
  }

  input[type=text], input[type=password], input[type=email], input[type=number], input[type=tel], input[type=date], input[type=time], select {
	border-bottom: 1px solid $warning !important;
	margin-bottom: 15px;
  }
}

.tab-pane.container.active header * {
  color: #f4ede1 !important;
}

.tab-pane.container header::after {
  content: "";
  position: absolute;
  top: 80px;
  left: 0;
  height: 1px;
  width: 100%;
  display: block;
  background: $warning;
  z-index: 102;
}

.fileuploader #upload-label {
  cursor: pointer !important;
  border: 1px solid #c7ac9c;
  color: #f4ede1;
  border-radius: 0.24rem;
  margin-top: 30px;
  margin-bottom: 15px;
}


#addChild {
  height: 24px;
  width: 24px;
  padding: 10px;
  border-radius: 50px;
  background-color: $warning;
  border: 1px solid $white;
  color: #627260;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.tab-content h4 {
  color: $white !important;
}

.product-card h4 {
  color: #627260 !important;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 40px !important;
  /* padding-left: 1.25rem; */
}

.square-btn {
  border-radius: 8px !important;
}


.tab-content .form-control-plaintext.input-border-danger,
.input-border-danger {
  border-bottom: 1px solid #c2765a !important;
}

.card-box {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 7px 3px rgba(204, 198, 198, 1);
  transition: transform .45s ease, background .45s ease;
  border-radius: 0.5em;
  text-decoration: none;
  background: #c7ac9c;
  border: 1px solid $danger;
}

.fileDetails {
  display: block;
  width: 100%;
  border: 1px solid $white;
  padding: 15px;
  margin-bottom: 45px;
  position: relative;
  color: $white !important;
  font-weight: 400;

  &::after {
	content: '\2713';
	position: absolute;
	right: 0;
	top: 15px;
	display: block;
	height: 30px;
	width: 30px;
	color: #f4ede1;
  }
}

.mt-20 {
  margin-top: 50px !important;
}

html {
  body {
	section#ccmo-form {

	  h1,
	  h2,
	  h3,
	  h4,
	  h5,
	  h6 {
		margin: 0 0 30px 0;
		font-family: 'Forum', cursive;
	  }

	  h1 {
		font-size: 34px;
	  }

	  h2 {
		font-size: 28px;
		line-height: 30px;
	  }

	  h3 {
		font-size: 20px;
	  }

	  h4 {
		font-size: 18px;
		line-height: 26px;
	  }

	  h5 {
		font-size: 16px;
		line-height: 26px;
	  }

	  h6 {
		font-size: 16px;
	  }

	  p {
		font-size: 16px;
		margin: 0 0 15px 0;
	  }

	  p:last-child {
		margin: 0;
	  }

	  a {
		color: inherit;
	  }

	  a:hover {
		color: $danger;
		text-decoration: none;
	  }

	  a:active,
	  a:hover {
		outline: 0;
		text-decoration: none;
	  }

	  .btn-secondary {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	  }

	  .btn {
		font-size: 14px;
		padding: 9px 16px;
		border-radius: 2px;
	  }


	  .tab-vertical .nav.nav-tabs {
		float: left;
		display: block;
		margin-right: 0;
		border-bottom: 0;
	  }

	  .tab-vertical .nav.nav-tabs .nav-item {
		margin-bottom: 6px;
	  }

	  .tab-vertical .nav-tabs .nav-link {
		border: 1px solid transparent;
		border-top-left-radius: .25rem;
		border-top-right-radius: .25rem;
		background: $white;
		padding: 17px 49px;
		color: #71748d;
		background-color: $white;
		-webkit-border-radius: 4px 0 0 4px;
		-moz-border-radius: 4px 0 0 4px;
		border-radius: 4px 0 0 4px;
	  }

	  .tab-vertical .nav-tabs .nav-link.active {
		color: $primary;
		background-color: #fff !important;
		border-color: transparent !important;
	  }

	  .tab-vertical .nav-tabs .nav-link {
		border: 1px solid transparent;
		border-top-left-radius: 4px !important;
		border-top-right-radius: 0px !important;
	  }

	  .tab-vertical .tab-content {
		overflow: auto;
		-webkit-border-radius: 0px 4px 4px 4px;
		-moz-border-radius: 0px 4px 4px 4px;
		border-radius: 0px 4px 4px 4px;
		background: $primary;
		padding: 30px;
	  }

	  .nav-pills .nav-link,
	  .nav-pills-custom {
		border-radius: 0;
		border-bottom-left-radius: 8px;
		border-top-left-radius: 8px;
	  }

	  .nav-pills-custom .nav-link {
		color: $white;
		background: $primary;
		position: relative;
		height: 90px;
	  }

	  .nav-pills-custom .nav-link.active {
		color: $primary;
		background: $white;
		height: 90px;
	  }


	  /* Add indicator arrow for the active tab */
	  @media (min-width: 992px) {
		.nav-pills-custom .nav-link::before {
		  content: '';
		  display: block;
		  border-top: 8px solid transparent;
		  border-left: 10px solid $white;
		  border-bottom: 8px solid transparent;
		  position: absolute;
		  top: 50%;
		  right: -10px;
		  transform: translateY(-50%);
		  opacity: 0;
		}
	  }

	  .nav-pills-custom .nav-link.active::before {
		opacity: 1;
	  }

	  .tab-content {
		border-left: 1px solid $warning;
		border-top: 1px solid $warning;
		position: relative;
		width: 100%;
		background-color: #627260 !important;
	  }

	  .p-x25 {
		padding: 25px 0px 25px 0px
	  }

	  header h3 {
		font-size: 34px;
	  }


	  .form-control-plaintext {
		background-color: transparent;
		border: solid transparent;
		border-width: 1px 0;
		color: #212529;
		display: block;
		line-height: 1.5;
		margin-bottom: 0;
		padding: 0.375rem 0;
		width: 100%;
		border-bottom: 1px solid $warning !important;
	  }

	}
  }
}

#floating-header {
  position: fixed;
  max-height: 70px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  padding: 15px 15px 15px 70px !important;
}



.btn-outline-light {
  border-color: #f4ede1!important;
  color: #f4ede1!important;
  background-color: transparent!important;
  &:hover, &:active {
	border-color: #f4ede1!important;
	color: $success!important;
	background-color: #f4ede1!important;
  }
}

//@media not all and (min-resolution:.001dpcm) {
//  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
//	html body section#ccmo-form input[type=date].form-control-plaintext,
//	html body section#ccmo-form input[type=time].form-control-plaintext {
//	  vertical-align: bottom;
//	  /* Solution 1 */
//	  -webkit-appearance: textfield;
//	  -moz-appearance: textfield;
//	  min-height: 1.2em;
//	  display: flex !important;
//	  padding-bottom: 0.27rem;
//	}
//  }
//}


.warning {
  background-color: $danger;
  border:1px solid $white;
  color:$white;
}

#gender-selection {
  .active {
	background-color: $warning!important;
	border-color: $warning!important;
	color:$white!important;
  }
}



#confirm {
  display: none;
  background-color: $white;
  color: #000000;
  border: 1px solid $warning;
  box-sizing: border-box;
  min-height: 100px;
  max-height: 520px;
  left: 50%;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 300px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  -moz-box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
#confirm button {
  background-color: #f4ede1;
  border: 2px solid #c7ac9c;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  text-align: center;
  width: 90px;
}
#confirm .message {
  text-align: left;
}